<template>
  <div>
    <div class="d-flex justify-content-between align-items-center wikiDescription__heading">
      <h6>{{ getWidgetName.replace(/[\s-]|Widget/gi, ' ') }}</h6>
      <TextToSpeech :speech="content.description" :label="label"/>
    </div>
    <div>
      <iframe
        :src="iframeSourceUrl"
        frameborder="0"
        width="100%"
        height="1024"
        allow="camera; microphone; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture "
        allowfullscreen
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import TextToSpeech from '@/common/components/TextToSpeech.vue';

export default {
  name: 'Help',
  computed: {
    getWidgetName() {
      return this.$store.state.widgetName;
    },
    iframeSourceUrl() {
      return `${process.env.VUE_APP_TJ_DICTIONARY_URI}/${this.getWidgetName}.html`;
    },
  },
  data() {
    return {
      content: {
        tjType: 'note-text',
        description: "You haven't selected a text yet.",
      },
      label: 'help',
    };
  },
  methods: {},

  components: {
    TextToSpeech,
  },
};
</script>

<style lang="scss" scoped></style>
